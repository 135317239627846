@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-primary {
    @apply text-sm font-semibold text-gray-600;
  }

  .text-secondary {
    @apply text-sm font-medium text-gray-600;
  }

  .text-informative {
    @apply text-sm font-light text-gray-500 italic;
  }
}

@layer utilities {

  /* Diagonal Stripes Overlay */
  .diagonal-stripes-overlay {
    @apply absolute top-0 left-0 w-full h-full transition-opacity duration-500 rounded-md cursor-not-allowed;
    background-image: linear-gradient(-45deg,
        rgba(230, 230, 230, 0.5) 25%,
        transparent 25%,
        transparent 50%,
        rgba(230, 230, 230, 0.5) 50%,
        rgba(230, 230, 230, 0.5) 75%,
        transparent 75%,
        transparent);
    background-size: 20px 20px;
    transition: opacity 0.5s, background-color 0.5s;
  }

  :root {
    --tw-color-green-100: #D1FAE5;
    --tw-color-red-100: #FEE2E2;
  }

  /* Then use the variables in your keyframes */
  @keyframes fadeOutGrayToGreen {
    0% {
      opacity: 1;
      background-color: transparent;
    }

    100% {
      opacity: 0;
      background-color: rgba(209, 250, 229, 1);
      /* green-100 */
    }
  }

  @keyframes fadeInRedToGray {
    0% {
      opacity: 0;
      background-color: rgba(254, 226, 226, 1);
      /* red-100 */
    }

    100% {
      opacity: 1;
      background-color: transparent;
    }
  }

  @variants responsive {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

@keyframes countdown {
  from {
    left: 0;
    right: auto;
    width: 100%;
  }

  to {
    left: 100%;
    right: auto;
    width: 0%;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    /* Start from below the screen */
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slideIn {
  animation: slideInFromBottom 0.2s ease-out;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-3px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(3px);
  }
}

.animate-shake {
  animation: shake 0.3s;
  animation-iteration-count: 1;
}

.bg-diagonal-stripes {
  background-image: linear-gradient(-45deg, #e6e6e6 25%, transparent 25%, transparent 50%, #e6e6e6 50%, #e6e6e6 75%, transparent 75%, transparent);
  background-size: 5px 5px;
}

/* Tailwind CSS custom tooltip styles */
.tooltip {
  @apply relative;
}

.tooltip-text {
  @apply invisible absolute w-max bg-black font-extralight text-white text-xs text-center rounded-md py-1 px-2 opacity-0 transition-opacity duration-300;
}

.tooltip:hover .tooltip-text {
  @apply visible opacity-100;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  z-index: 1000;
}